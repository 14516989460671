.MuiPaper-root.result {
	width: 100%;
	height: 100px;
	text-align: center;
	line-height: 120px;
	vertical-align: middle;
	background-color: #f5f4f4;
}

.MuiInputBase-root.MuiOutlinedInput-root {
	border-radius: 4px 0px 0px 4px;
}

.carbonbadge {
	margin-top: 15px;
}
